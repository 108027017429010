export const FETCH_PROMOTER_ROLES_SUCCESS = 'FETCH_PROMOTER_ROLES_SUCCESS'
export const FETCH_PROMOTERS_SUCCESS = 'FETCH_PROMOTERS_SUCCESS'
export const GET_RELATED_PROMOTERS_SUCCESS = 'GET_RELATED_PROMOTERS_SUCCESS'
export const GET_PROMOTER_SUCCESS = 'GET_PROMOTER_SUCCESS'
export const ADD_PROMOTER_SUCCESS = 'ADD_PROMOTER_SUCCESS'
export const UPDATE_PROMOTER_SUCCESS = 'UPDATE_PROMOTER_SUCCESS'
export const DELETE_PROMOTER_SUCCESS = 'DELETE_PROMOTER_SUCCESS'

export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
