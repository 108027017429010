import ApplicationPolicyUserModel from './application_policy_user_model'
/**
 * Promoter user model
 *
 * @export
 * @class Promoter
 */
export default class PromoterMember extends ApplicationPolicyUserModel {
  constructor(jwtPayload) {
    super(jwtPayload)
  }
}
