import Money from 'odd-money'
import customCurrencies from '../custom_currencies.js'

const SignedInScopeTypeMap = {
  user: 'User',
  admin: 'Admin',
  promoter_member: 'PromoterMember'
}

function install(
  Vue,
  options = {
    envScope: 'user'
  }
) {
  Vue.mixin({
    computed: {
      currentUser() {
        return this.$store.getters['currentUser']
      },

      isUserSignedIn() {
        return this.currentUser.type === SignedInScopeTypeMap[options.envScope]
      },

      currencyReferenceConfig() {
        return this.$store.getters['users/currencyReferenceConfig']
      },

      currentCurrency() {
        return this.currencyReferenceConfig.selectedCurrency || 'TWD'
      }
    },

    methods: {
      simpleFormat(text) {
        if (text) {
          return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
        }
      },

      toMoney(
        cents,
        options = {
          isExchange: true,
          currency: this.currentCurrency,
          customCurrencies: null
        }
      ) {
        if (options.currency === 'TWD' || !options.isExchange)
          return new Money(cents, 'TWD', options.customCurrencies)

        cents =
          Math.round(
            (cents * this.currencyReferenceConfig.currencyRate) / 100
          ) * 100

        return new Money(cents, options.currency, options.customCurrencies)
      },

      moneyFromAmount(amount, currency = 'TWD') {
        return Money.fromAmount(amount, currency, customCurrencies)
      },

      currentUnixTime() {
        return Math.floor(Date.now() / 1000)
      },

      /**
       * fetch prevPageInfo from indexDB.
       * if current URL equal to prevPageInfo.url, find element by ID every 1 sec until meet retryLimit or element
       * found. if element found, smooth scroll it into view.
       * @param {number} retryLimit retry limit for find element and scroll to
       * @returns {Promise<void>}
       */
      async tryRestoreScrollPosition(retryLimit = 10) {
        const prevPageInfo = await this.$vlf.getItem('prevPage')

        if (!prevPageInfo) return

        const currentUrl = window.location.href
        let retry = 0
        const findElementAndScrollTo = async () => {
          const element = document.querySelector(`#${prevPageInfo.leaveFrom}`)

          if (retry <= retryLimit && !element) {
            setTimeout(() => {
              retry += 1
              findElementAndScrollTo()
            }, 1000)
          }

          if (!!element) {
            element.scrollIntoView({ behavior: 'smooth' })
            await this.$vlf.removeItem('prevPage')
          }
        }

        if (prevPageInfo.url === currentUrl) await findElementAndScrollTo()
      }
    }
  })
}

export default install
