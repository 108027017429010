import * as types from '../mutation-types'

export const fetchProducts = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchProducts')

  return new Promise((resolve, reject) => {
    model
      .fetchProducts(options)
      .then((response) => {
        if (options.replace) {
          dispatch(
            'products/receiveResourcesFromRelationshipsWithReplace',
            response,
            {
              root: true
            }
          )
        } else {
          dispatch('products/receiveResourcesFromRelationships', response, {
            root: true
          })
        }

        commit(types.API_REQUEST_SUCCESS, 'fetchProducts')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchProducts,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchTopProducts = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'fetchTopProducts')

  return new Promise((resolve, reject) => {
    model
      .fetchTopProducts(options)
      .then((response) => {
        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'fetchTopProducts')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchTopProducts,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateTopProductIds = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateTopProductIds')

  return new Promise((resolve, reject) => {
    model
      .updateTopProductIds()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateTopProductIds,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
