import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'adjustments',
  attributes: [
    'id',
    'title',
    'amount',
    'is_fullfilled',
    'state',
    'adjustable_type',
    'adjustable_id'
  ],
  editableAttributes: []
}

export default class Adjustment extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
  apiBasePath() {
    return null
  }
}
