import PromoterEvent from '../../resource_models/promoter_event'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new PromoterEvent(state.entities[id]))
}

export const find = (state) => (id) => {
  return new PromoterEvent(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}

export const profitShareRules = (state) => {
  return state.profitShareRules
}

export const defaultProfitCalculateMethods = (state) => {
  return state.defaultProfitCalculateMethods
}

export const defaultEvent = (state) => {
  return state.result
    .map((id) => new PromoterEvent(state.entities[id]))
    .find((promoterEvent) => {
      return promoterEvent.is_default
    })
}

export const dashboardData =
  (state) =>
  (scope = null) => {
    if (scope) {
      return state.dashboard[scope]
    } else {
      return state.dashboard
    }
  }

export const statsData =
  (state) =>
  (scope = null) => {
    return state.stats[scope] ? state.stats[scope].data : []
  }
