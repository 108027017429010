import * as types from './mutation-types'
import MutationHelpers from '../mutation_helpers'

const helpers = new MutationHelpers('promoter_events')

export default {
  [types.FETCH_PROMOTER_EVENT_PROFIT_SHARE_RULES_SUCCESS](state, response) {
    state.profitShareRules = response.data.data

    state.isCallingAPI = false
  },

  [types.FETCH_PROMOTER_EVENT_DEFAULT_PROFIT_CALCULATE_METHODS_SUCCESS](
    state,
    response
  ) {
    state.defaultProfitCalculateMethods = response.data.data

    state.isCallingAPI = false
  },

  [types.FETCH_PROMOTER_EVENTS_SUCCESS](state, response) {
    helpers.replaceEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_DASHBOARD_DATA_SUCCESS](state, { response, dataType }) {
    const keys = Object.keys(response.data)
    state.isCallingAPI = false

    keys.forEach((key) => {
      Vue.set(state.dashboard, key, response.data[key])
    })
  },

  [types.GET_RELATED_PROMOTER_EVENTS_SUCCESS](state, response) {
    helpers.storeResourcesToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_PROMOTER_EVENT_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.FETCH_DEFAULT_PROMOTER_EVENT_SUCCESS](state, response) {
    helpers.storeResourcesToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.ADD_PROMOTER_EVENT_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)
    helpers.pushResourceToResult(state, response)

    state.isCallingAPI = false
  },

  [types.UPDATE_PROMOTER_EVENT_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.DELETE_PROMOTER_EVENT_SUCCESS](state, id) {
    helpers.removeOneResourceFromEntities(state, id)

    state.isCallingAPI = false
  },

  [types.API_REQUEST_START](state, action = 'not provided') {
    state.isCallingAPI = true
  },

  [types.API_REQUEST_SUCCESS](state, action = 'not provided') {
    state.isCallingAPI = false
  },

  [types.API_REQUEST_FAIL](state, errors) {
    state.errors.record(errors)
    state.isCallingAPI = false
  },

  [types.GET_STATS_SUCCESS](state, { statsKey, response }) {
    state.isCallingAPI = false

    Vue.set(state.stats, statsKey, response.data)
  }
}
