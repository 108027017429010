import axios from 'axios'
import moment from 'moment'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_shares',
  attributes: [
    'id',
    'hashid',
    'event_id',
    'parent_id',
    'promoter_id',
    'promoter_name',
    'promoter_email',
    'description',
    'is_enabled',
    'token',
    'depth',
    'lft',
    'rgt',
    'share_product_ships',
    'is_suspended',
    'suspended_at',
    'is_template',
    'sign_up_token',
    'top_product_ids',
    'is_meta_noindex',
    'meta_title',
    'meta_description',
    'meta_keywords',
    'slug',
    'current_slug',
    'created_at'
  ],
  editableAttributes: [
    'event_id',
    'promoter_id',
    'parent_id',
    'is_suspended',
    'share_product_ships',
    'description',
    'uploaded_attachment_ids',
    'top_product_ids',
    'is_meta_noindex',
    'meta_title',
    'meta_description',
    'meta_keywords',
    'slug'
  ]
}

export default class PromoterShare extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static findWithoutProducts(id) {
    return axios.get(`${new this().apiBasePath()}/${id}?skip_products=1`)
  }

  storeFrontUrl() {
    return `${window.location.origin}/me/${this.current_slug}`
  }

  checkOrderLink() {
    return `/me/${this.current_slug}/check_order`
  }

  static fetchDashboardData(dataType, options) {
    let optionsQueryString = Object.keys(options)
      .map((key) => `${key}=${options[key]}`)
      .join('&')
    return axios.get(
      `${new this().apiBasePath()}/dashboard/${dataType}?${optionsQueryString}`
    )
  }

  toggle() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle`)
  }

  revokeToken() {
    return axios.post(`${this.apiBasePath()}/${this.id}/revoke_token`)
  }

  updateInfo() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/info`,
      this.requestBody()
    )
  }

  updateSeo() {
    return axios.put(`${this.apiBasePath()}/${this.id}/seo`, this.requestBody())
  }

  updateTopProductIds() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/top_product_ids`,
      this.requestBody()
    )
  }

  // order_ships
  fetchOrders(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/orders?${FetchingDataOptionsService.call(options)}`
    )
  }

  // check_order
  checkOrder(options) {
    const requestBody = {
      data: {
        type: 'promoter-share-check-order',
        attributes: {
          phone: options.phone,
          email: options.email
        }
      }
    }
    return axios.post(
      `${this.apiBasePath()}/${this.id}/check_order`,
      requestBody
    )
  }

  // descendants
  fetchDescendants() {
    return axios.get(`${this.apiBasePath()}/${this.id}/descendants`)
  }

  // products
  fetchProducts(options = {}) {
    let query = FetchingDataOptionsService.call(options)

    if (options.share_product_ship_is_available)
      query += '&share_product_ship_is_available=1'

    return axios.get(`${this.apiBasePath()}/${this.id}/products?${query}`)
  }

  fetchTopProducts(options = {}) {
    return axios.get(`${this.apiBasePath()}/${this.id}/top_products`)
  }

  toggleProductShipAvailability(options) {
    const requestBody = {
      data: {
        type: 'promoter-share-toggle-product-ship-availability',
        attributes: {
          share_product_ship_id: options.shareProductShipId
        }
      }
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/toggle_product_ship_availability`,
      requestBody
    )
  }

  /**
   * 將此 promoter 停權
   *
   * @returns {Promise} 回傳 response 或 errors
   */
  suspend(options) {
    const requestBody = {
      data: {
        type: 'promoter-share-suspend',
        attributes: {
          include_descendants: options.include_descendants
        }
      }
    }
    return axios.put(`${this.apiBasePath()}/${this.id}/suspend`, requestBody)
  }

  shareVariantShips() {
    return this.share_product_ships
      .map((shareProductShip) => {
        return shareProductShip.share_variant_ships
      })
      .flat()
  }

  static uploadAttachments(formData) {
    return axios.post(
      `${new this().apiBasePath({
        withResourceType: false
      })}/editor_attachments`,
      formData
    )
  }

  fetchShareProductShips(options = {}) {
    let query = FetchingDataOptionsService.call(options)

    return axios.get(
      `${this.apiBasePath()}/${this.id}/share_product_ships?${query}`
    )
  }

  fetchShareProductShipsV2(options = {}) {
    let query = FetchingDataOptionsService.call(options)

    return axios.get(
      `${this.apiBasePath()}/${this.id}/share_product_ships/v2?${query}`
    )
  }

  fetchEventProductShips(options = {}) {
    let query = FetchingDataOptionsService.call(options)

    return axios.get(
      `${this.apiBasePath()}/${this.id}/event_product_ships?${query}`
    )
  }

  toggleShareProductShip({ eventProductShipId }) {
    const requestBody = {
      data: {
        type: 'promoter-share-check-order',
        attributes: {
          event_product_ship_id: eventProductShipId
        }
      }
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/toggle_share_product_ship`,
      requestBody
    )
  }
}
