export default class ExtraQueryStringParseService {
  static call(options) {
    let result = `${this.tag(options)}`

    return result
  }

  static tag(options) {
    let tag = options.tag

    if (tag) return `&tag=${tag}`

    return ''
  }
}
