import * as types from './mutation-types'
import PromoterMember from '../../resource_models/promoter_member'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    PromoterMember.all(options)
      .then((response) => {
        commit(types.FETCH_PROMOTER_MEMBERS_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    PromoterMember.find(id)
      .then((response) => {
        dispatch('promoters/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.GET_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_PROMOTER_MEMBER_SUCCESS, response)
        } else {
          commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroy = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model
      .destroy()
      .then((response) => {
        commit(types.DELETE_PROMOTER_MEMBER_SUCCESS, model.id)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroy,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const signUp = ({ dispatch, commit }, { model }) => {
  commit(types.API_REQUEST_START, 'updatePhotos')

  return new Promise((resolve, reject) => {
    PromoterMember.signUp(model)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: signUp,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              formData
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

// update photos
export const updatePhotos = ({ dispatch, commit }, { model, formData }) => {
  commit(types.API_REQUEST_START, 'updatePhotos')

  return new Promise((resolve, reject) => {
    model
      .updatePhotos(formData)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updatePhotos,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              formData
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

// update profile
export const updateProfile = ({ dispatch, commit }, { model, promoterId }) => {
  commit(types.API_REQUEST_START, 'updateProfile')

  return new Promise((resolve, reject) => {
    model
      .updateProfile(promoterId)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProfile,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              promoterId
            }
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

// update bandAccountInfo
export const updateBankAccountInfo = (
  { dispatch, commit },
  { model, promoterId }
) => {
  commit(types.API_REQUEST_START, 'updateBankAccountInfo')

  return new Promise((resolve, reject) => {
    model
      .updateBankAccountInfo(promoterId)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateBankAccountInfo,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              promoterId
            }
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

// update password
export const updatePassword = ({ dispatch, commit }, { model, promoterId }) => {
  commit(types.API_REQUEST_START, 'updatePassword')

  return new Promise((resolve, reject) => {
    model
      .updatePassword(promoterId)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updatePassword,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              promoterId
            }
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_PROMOTER_MEMBERS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_PROMOTER_MEMBER_SUCCESS, response)

    resolve(response)
  })
}
