import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_share_product_ships',
  attributes: [
    'id',
    'share_id',
    'event_product_ship_id',
    'product_id',
    'is_available',
    'is_sold_out',
    'created_at',
    'updated_at'
  ],
  editableAttributes: []
}

export default class PromoterShareProductShip extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  toggleAvailable() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_available`)
  }
}
