import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoters',
  attributes: [
    'created_at',
    'email',
    'name',
    'id',
    'photo',
    'is_suspended',
    'media_followers',
    'bg_image',
    'self_intro',
    'parent_id',
    'lft',
    'rgt',
    'depth',
    'role',
    'build_source',
    'is_allowed_to_add_child',
    'children_count',
    'promoter_member_id',
    'profile',
    'bank_account_info'
  ],
  editableAttributes: [
    'email',
    'name',
    'id',
    'password',
    'new_password',
    'password_confirmation',
    'media_followers',
    'self_intro',
    'parent_id',
    'role',
    'is_allowed_to_add_child',
    'promoter_member_id',
    'profile',
    'bank_account_info'
  ]
}

export default class Promoter extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  fetchShares(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/shares?${FetchingDataOptionsService.call(options)}`
    )
  }

  static fetchDashboardData(dataType, options) {
    let optionsQueryString = Object.keys(options)
      .map((key) => `${key}=${options[key]}`)
      .join('&')
    return axios.get(
      `${new this().apiBasePath()}/dashboard/${dataType}?${optionsQueryString}`
    )
  }

  isRoot() {
    return !this.parent_id
  }

  isAncestorOf(promoter) {
    return this.lft < promoter.lft && this.rgt > promoter.rgt
  }

  isOrIsAncestorOf(promoter) {
    return this.lft <= promoter.lft && this.rgt >= promoter.rgt
  }

  updatePhotos(formData) {
    return axios.put(`${this.apiBasePath()}/${this.id}/photos`, formData)
  }

  hasPhoto(photoKey, photoValue) {
    return this[photoKey][photoValue] && this[photoKey][photoValue].url !== null
  }

  updatePassword() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/password`,
      this.requestBody()
    )
  }

  updateProfile() {
    const body = {
      id: this.id,
      attributes: Object.assign(this.profile, {
        email: this.email
      })
    }

    return axios.put(`${this.apiBasePath()}/${this.id}/profile`, body)
  }

  updateBankAccountInfo() {
    const body = {
      id: this.id,
      data: {
        type: 'promoter_account_info',
        attributes: this.bank_account_info
      }
    }

    return axios.put(`${this.apiBasePath()}/${this.id}/bank_account_info`, body)
  }

  /**
   * 將此 promoter 停權
   *
   * @returns {Promise} 回傳 response 或 errors
   */
  suspend(options) {
    const requestBody = {
      data: {
        type: 'promoter-suspend',
        attributes: {
          include_descendants: options.include_descendants
        }
      }
    }
    return axios.put(`${this.apiBasePath()}/${this.id}/suspend`, requestBody)
  }

  /**
   * 將此 promoter 切換加入會員權限
   *
   * @returns {Promise} 回傳 response 或 errors
   */
  allowToAddChild(options) {
    const requestBody = {
      data: {
        type: 'promoter-allow-to-add-child',
        attributes: {
          include_descendants: options.include_descendants
        }
      }
    }
    return axios.put(
      `${this.apiBasePath()}/${this.id}/allow_to_add_child`,
      requestBody
    )
  }

  /**
   * 從 server 取得所有 Promoter 可用的 roles 列表
   *
   * @returns {promise}
   */
  static roles() {
    return axios.get(`${new this().apiBasePath()}/roles`)
  }

  createWithExistedPromoterMember() {
    return axios.post(
      `${this.apiBasePath()}/new_with_existed_promoter_member`,
      this.requestBody()
    )
  }
}
