export const FETCH_PROMOTER_MEMBERS_SUCCESS = 'FETCH_PROMOTER_MEMBERS_SUCCESS'
export const GET_RELATED_PROMOTER_MEMBERS_SUCCESS =
  'GET_RELATED_PROMOTER_MEMBERS_SUCCESS'
export const GET_PROMOTER_MEMBER_SUCCESS = 'GET_PROMOTER_MEMBER_SUCCESS'
export const ADD_PROMOTER_MEMBER_SUCCESS = 'ADD_PROMOTER_MEMBER_SUCCESS'
export const UPDATE_PROMOTER_MEMBER_SUCCESS = 'UPDATE_PROMOTER_MEMBER_SUCCESS'
export const DELETE_PROMOTER_MEMBER_SUCCESS = 'DELETE_PROMOTER_MEMBER_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
