import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_members',
  attributes: [
    'id',
    'email',
    'name',
    'promoters',
    'profile',
    'bank_account_info',
    'created_at',
    'updated_at'
  ],
  editableAttributes: [
    'email',
    'name',
    'password',
    'new_password',
    'password_confirmation',
    'profile',
    'bank_account_info'
  ]
}

export default class PromoterMember extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static signUp(model) {
    return axios.post(`${new this().apiBasePath()}/sign_up`, {
      data: {
        type: 'sign_up',
        attributes: model.attributes()
      }
    })
  }

  updatePhotos(formData) {
    return axios.put(`${this.apiBasePath()}/${this.id}/photos`, formData)
  }

  updateProfile(promoterId) {
    const body = {
      promoter_id: promoterId,
      data: {
        type: 'promoter_profile',
        attributes: Object.assign(this.profile, {
          email: this.email
        })
      }
    }

    return axios.put(`${this.apiBasePath()}/${this.id}/profile`, body)
  }

  updateBankAccountInfo(promoterId) {
    const body = {
      promoter_id: promoterId,
      data: {
        type: 'promoter_account_info',
        attributes: this.bank_account_info
      }
    }

    return axios.put(`${this.apiBasePath()}/${this.id}/bank_account_info`, body)
  }

  updatePassword(promoterId) {
    const body = {
      promoter_id: promoterId,
      data: {
        type: 'promoter_member',
        attributes: {
          new_password: this.new_password,
          password_confirmation: this.password_confirmation
        }
      }
    }

    return axios.put(`${this.apiBasePath()}/${this.id}/password`, body)
  }

  // extra methods or helpers here...
  hasPhoto(photoKey, photoValue) {
    return this[photoKey][photoValue] && this[photoKey][photoValue].url !== null
  }
}
