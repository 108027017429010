import * as types from './mutation-types'
import PromoterEvent from '../../resource_models/promoter_event'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    PromoterEvent.all(options)
      .then((response) => {
        commit(types.FETCH_PROMOTER_EVENTS_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    PromoterEvent.find(id)
      .then((response) => {
        commit(types.GET_PROMOTER_EVENT_SUCCESS, response)
        dispatch('promoters/receiveResourcesFromRelationships', response, {
          root: true
        })
        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_PROMOTER_EVENT_SUCCESS, response)
        } else {
          commit(types.UPDATE_PROMOTER_EVENT_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroy = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model
      .destroy()
      .then((response) => {
        commit(types.DELETE_PROMOTER_EVENT_SUCCESS, model.id)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroy,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchStats = ({ dispatch, commit }, { statsKey, options }) => {
  commit(types.API_REQUEST_START, 'fetchStats')

  return new Promise((resolve, reject) => {
    PromoterEvent.fetchStats(options)
      .then((response) => {
        commit(types.GET_STATS_SUCCESS, {
          statsKey,
          response
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchStats,
            ref: {
              dispatch,
              commit
            },
            params: { statsKey, options }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateCover = ({ dispatch, commit }, { model, formData }) => {
  commit(types.API_REQUEST_START, 'updateCover')

  return new Promise((resolve, reject) => {
    model
      .updateCover(formData)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateCover,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              formData
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const removeCover = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'removeCover')

  return new Promise((resolve, reject) => {
    model
      .removeCover()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: removeCover,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              formData
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleEnabled = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'toggleEnabled')

  return new Promise((resolve, reject) => {
    model
      .toggleEnabled()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleEnabled,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleEnabledProfitShare = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'toggleEnabledProfitShare')

  return new Promise((resolve, reject) => {
    model
      .toggleEnabledProfitShare()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleEnabledProfitShare,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleLockedProfitShareRule = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'toggleLockedProfitShareRule')

  return new Promise((resolve, reject) => {
    model
      .toggleLockedProfitShareRule()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleLockedProfitShareRule,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchOrderStats = (
  { dispatch, commit },
  { statsKey, options }
) => {
  commit(types.API_REQUEST_START, 'fetchOrderStats')

  return new Promise((resolve, reject) => {
    PromoterEvent.fetchOrderStats(options)
      .then((response) => {
        commit(types.GET_STATS_SUCCESS, {
          statsKey,
          response
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchOrderStats,
            ref: {
              dispatch,
              commit
            },
            params: { statsKey, options }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchOrders = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'fetchOrders')

  return new Promise((resolve, reject) => {
    model
      .fetchOrders(options)
      .then((response) => {
        dispatch(
          'orders/receiveResourcesFromRelationshipsWithReplace',
          response,
          {
            root: true
          }
        )
        commit(types.API_REQUEST_SUCCESS, 'fetchOrders')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchOrders,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchProfitShareRules = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchProfitShareRules')

  return new Promise((resolve, reject) => {
    PromoterEvent.profitShareRules()
      .then((response) => {
        commit(types.FETCH_PROMOTER_EVENT_PROFIT_SHARE_RULES_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchProfitShareRules,
            ref: {
              dispatch,
              commit
            },
            params: {}
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchDefaultProfitCalculateMethods = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchDefaultProfitCalculateMethods')

  return new Promise((resolve, reject) => {
    PromoterEvent.defaultProfitCalculateMethods()
      .then((response) => {
        commit(
          types.FETCH_PROMOTER_EVENT_DEFAULT_PROFIT_CALCULATE_METHODS_SUCCESS,
          response
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchDefaultProfitCalculateMethods,
            ref: {
              dispatch,
              commit
            },
            params: {}
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

// products

export const createProductShips = (
  { dispatch, commit },
  { model, productIds }
) => {
  commit(types.API_REQUEST_START, 'createProductShips')

  return new Promise((resolve, reject) => {
    model
      .createProductShips(productIds)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: createProductShips,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              productIds
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateProductShips = (
  { dispatch, commit },
  { model, productShips }
) => {
  commit(types.API_REQUEST_START, 'updateProductShips')

  return new Promise((resolve, reject) => {
    model
      .updateProductShips(productShips)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProductShips,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              productShips
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const deleteProductShip = (
  { dispatch, commit },
  { model, productShip }
) => {
  commit(types.API_REQUEST_START, 'deleteProductShips')

  return new Promise((resolve, reject) => {
    model
      .deleteProductShips(productShip)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'deleteProductShips')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: deleteProductShips,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              productShip
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchProducts = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchProducts')

  return new Promise((resolve, reject) => {
    model
      .fetchProducts(options)
      .then((response) => {
        if (options.replace) {
          dispatch(
            'products/receiveResourcesFromRelationshipsWithReplace',
            response,
            {
              root: true
            }
          )
        } else {
          dispatch('products/receiveResourcesFromRelationships', response, {
            root: true
          })
        }

        commit(types.API_REQUEST_SUCCESS, 'fetchProducts')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchProducts,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const findProduct = ({ dispatch, commit }, { model, productId }) => {
  commit(types.API_REQUEST_START, 'findProduct')

  return new Promise((resolve, reject) => {
    model
      .findProduct(productId)
      .then((response) => {
        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        dispatch(
          'productOptionTypes/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        dispatch('productImages/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'findProduct')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: findProduct,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              productId
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

// shareRanges
export const createShareRange = ({ dispatch, commit }, { model, data }) => {
  commit(types.API_REQUEST_START, 'createShareRange')

  return new Promise((resolve, reject) => {
    model
      .createShareRange(data)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: createShareRange,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              data
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateShareRanges = (
  { dispatch, commit },
  { model, shareRanges }
) => {
  commit(types.API_REQUEST_START, 'updateShareRanges')

  return new Promise((resolve, reject) => {
    model
      .updateShareRanges(shareRanges)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateShareRanges,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              shareRanges
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const deleteShareRange = (
  { dispatch, commit },
  { model, shareRange }
) => {
  commit(types.API_REQUEST_START, 'deleteShareRange')

  return new Promise((resolve, reject) => {
    model
      .deleteShareRange(shareRange)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_EVENT_SUCCESS, response)
        // commit(types.API_REQUEST_SUCCESS, 'deleteShareRange')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: deleteShareRange,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              shareRange
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchDashboardData = (
  { dispatch, commit },
  { dataType, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchDashboardData')
  return new Promise((resolve, reject) => {
    PromoterEvent.fetchDashboardData(dataType, options)
      .then((response) => {
        commit(types.GET_DASHBOARD_DATA_SUCCESS, {
          response,
          dataType
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchDashboardData,
            ref: {
              dispatch,
              commit
            },
            params: {
              dataType,
              options
            }
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const getDefaultEvent = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'getDefaultEvent')

  return new Promise((resolve, reject) => {
    PromoterEvent.getDefaultEvent()
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'getDefaultEvent')
        dispatch('promoterShares/receiveResourcesFromRelationships', response, {
          root: true
        })
        commit(types.FETCH_DEFAULT_PROMOTER_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: getDefaultEvent,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const revokeSignUpToken = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'revokeSignUpToken')

  return new Promise((resolve, reject) => {
    PromoterEvent.revokeSignUpToken()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: revokeSignUpToken,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_PROMOTER_EVENTS_SUCCESS, response)

    resolve(response)
  })
}

export const receiveResourcesFromRelationshipsWithReplace = (
  { commit },
  response
) => {
  return new Promise((resolve, reject) => {
    commit(types.FETCH_PROMOTER_EVENTS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_PROMOTER_EVENT_SUCCESS, response)

    resolve(response)
  })
}

// duplicate
export const duplicate = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateCover')

  return new Promise((resolve, reject) => {
    model
      .duplicate()
      .then((response) => {
        commit(types.ADD_PROMOTER_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: duplicate,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              formData
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

// shares
export const fetchShares = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'fetchShares')

  return new Promise((resolve, reject) => {
    model
      .fetchShares(options)
      .then((response) => {
        dispatch('promoterShares/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch(
          'promoters/receiveResourcesFromRelationshipsWithReplace',
          response,
          {
            root: true
          }
        )
        commit(types.API_REQUEST_SUCCESS, 'fetchShares')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchShares,
            ref: {
              dispatch,
              commit
            },
            params: {
              model: model,
              options: options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
