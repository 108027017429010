import * as types from './mutation-types'
import Product from '../../resource_models/product'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    Product.all(options)
      .then((response) => {
        if (options.noReplace) {
          // TODO: removing noReplace option
          commit(types.GET_RELATED_PRODUCTS_SUCCESS, response)
        } else if (options.forSearch) {
          commit(types.FETCH_PRODUCTS_FOR_SEARCH_SUCCESS, response)
        } else {
          commit(types.FETCH_PRODUCTS_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    Product.find(id)
      .then((response) => {
        commit(types.GET_PRODUCT_SUCCESS, response)
        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        dispatch(
          'productOptionTypes/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        dispatch('productImages/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_PRODUCT_SUCCESS, response)
        } else {
          commit(types.UPDATE_PRODUCT_SUCCESS, response)
        }
        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        dispatch(
          'productOptionTypes/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        dispatch('productImages/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroy = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model
      .destroy()
      .then((response) => {
        commit(types.DELETE_PRODUCT_SUCCESS, model.id)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroy,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const onSaleProducts = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'onSaleProducts')

  return new Promise((resolve, reject) => {
    Product.onSaleProducts(options)
      .then((response) => {
        if (options.replace) {
          commit(types.FETCH_PRODUCTS_SUCCESS, response)
        } else {
          commit(types.GET_RELATED_PRODUCTS_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: onSaleProducts,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

/**
 * 取得熱門商品
 *
 * @param {Object} options 包含所有 FetchingDataOptionsService 需要的選項，以及 replace {Boolean}。若 replace 為 true 時會替換掉 vuex store 中的內容，若為 false 則只是將新內容 merge 或 union 進 vuex store。
 */
export const hotProducts = ({ dispatch, commit }, options = {}) => {
  commit(types.API_REQUEST_START, 'hotProducts')

  return new Promise((resolve, reject) => {
    Product.hotProducts(options)
      .then((response) => {
        if (options.replace) {
          commit(types.FETCH_PRODUCTS_SUCCESS, response)
        } else {
          commit(types.GET_RELATED_PRODUCTS_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: hotProducts,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const uploadImages = ({ dispatch, commit }, formData) => {
  commit(types.API_REQUEST_START, 'uploadImages')

  return new Promise((resolve, reject) => {
    Product.uploadImages(formData)
      .then((response) => {
        if (formData.get('product[product_id]')) {
          commit(types.UPDATE_PRODUCT_SUCCESS, response)
          dispatch(
            'productVariants/receiveResourcesFromRelationships',
            response,
            {
              root: true
            }
          )
          dispatch(
            'productOptionTypes/receiveResourcesFromRelationships',
            response,
            {
              root: true
            }
          )
          dispatch(
            'productImages/receiveResourcesFromRelationships',
            response,
            {
              root: true
            }
          )
        } else {
          commit(types.API_REQUEST_SUCCESS, 'uploadImages')
        }

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: uploadImages,
            ref: {
              dispatch,
              commit
            },
            params: formData
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const uploadAttachments = ({ dispatch, commit }, formData) => {
  commit(types.API_REQUEST_START, 'uploadAttachments')

  return new Promise((resolve, reject) => {
    Product.uploadAttachments(formData)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'uploadAttachments')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: uploadAttachments,
            ref: {
              dispatch,
              commit
            },
            params: formData
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const collect = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'collect')

  return new Promise((resolve, reject) => {
    model
      .collect()
      .then((response) => {
        dispatch(
          'userCollections/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        commit(types.API_REQUEST_SUCCESS, 'collect')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: collect,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const uncollect = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'uncollect')

  return new Promise((resolve, reject) => {
    model
      .uncollect()
      .then((response) => {
        dispatch(
          'userCollections/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        commit(types.API_REQUEST_SUCCESS, 'uncollect')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: uncollect,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchSupportDocuments = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'fetchSupportDocuments')

  return new Promise((resolve, reject) => {
    model
      .fetchSupportDocuments()
      .then((response) => {
        dispatch(
          'productSupportDocuments/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        commit(types.API_REQUEST_SUCCESS, 'fetchSupportDocuments')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchSupportDocuments,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const uploadSupportDocument = (
  { dispatch, commit },
  { model, formData }
) => {
  commit(types.API_REQUEST_START, 'uploadSupportDocument')

  return new Promise((resolve, reject) => {
    model
      .uploadSupportDocument(formData)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'uploadSupportDocument')
        dispatch(
          'productSupportDocuments/addResourceFromRelationship',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: uploadSupportDocument,
            ref: {
              dispatch,
              commit
            },
            params: formData
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const checkVariantsAvailability = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'checkVariantsAvailability')

  return new Promise((resolve, reject) => {
    model
      .checkVariantsAvailability()
      .then((response) => {
        // NOTE: in Product model (product.js), is_all_variants_available and
        //       has_variants_unavailable are not attributes, but they are
        //       included in the response. So, we just resolve the attributes
        //       for components that used this action, and components should
        //       handle the other two properties by themselves.
        commit(types.API_REQUEST_SUCCESS, 'checkVariantsAvailability')

        resolve(response.data.data.attributes)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: checkVariantsAvailability,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleAvailability = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'toggleAvailability')

  return new Promise((resolve, reject) => {
    model
      .toggleAvailability()
      .then((response) => {
        commit(types.UPDATE_PRODUCT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleAvailability,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleAvailabilityWithAllVariants = (
  { dispatch, commit },
  model
) => {
  commit(types.API_REQUEST_START, 'toggleAvailabilityWithAllVariants')

  return new Promise((resolve, reject) => {
    model
      .toggleAvailabilityWithAllVariants()
      .then((response) => {
        commit(types.UPDATE_PRODUCT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleAvailabilityWithAllVariants,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const duplicate = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'duplicate')

  return new Promise((resolve, reject) => {
    model
      .duplicate()
      .then((response) => {
        commit(types.UPDATE_PRODUCT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: duplicate,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchDistributionBases = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchDistributionBases')

  return new Promise((resolve, reject) => {
    model
      .fetchDistributionBases(options)
      .then((response) => {
        dispatch(
          'distributionBases/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        commit(types.API_REQUEST_SUCCESS, 'fetchDistributionBases')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchDistributionBases,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleBonusDeductible = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'toggleBonusDeductible')

  return new Promise((resolve, reject) => {
    model
      .toggleBonusDeductible()
      .then((response) => {
        commit(types.UPDATE_PRODUCT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleBonusDeductible,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleMemberLevelDiscountable = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'toggleMemberLevelDiscountable')

  return new Promise((resolve, reject) => {
    model
      .toggleMemberLevelDiscountable()
      .then((response) => {
        commit(types.UPDATE_PRODUCT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleMemberLevelDiscountable,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleIsTaxFree = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'toggleIsTaxFree')

  return new Promise((resolve, reject) => {
    model
      .toggleIsTaxFree()
      .then((response) => {
        commit(types.UPDATE_PRODUCT_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleIsTaxFree,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const clearStore = ({ dispatch, commit }) => {
  return new Promise((resolve, reject) => {
    commit(types.CLEAR_STORE)
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_PRODUCTS_SUCCESS, response)

    resolve(response)
  })
}

export const receiveResourcesFromRelationshipsForSearch = (
  { dispatch, commit },
  response
) => {
  return new Promise((resolve, reject) => {
    commit(types.FETCH_PRODUCTS_FOR_SEARCH_SUCCESS, response)
    dispatch('productVariants/receiveResourcesFromRelationships', response, {
      root: true
    })

    resolve(response)
  })
}

export const receiveResourcesFromRelationshipsWithReplace = (
  { commit },
  response
) => {
  return new Promise((resolve, reject) => {
    commit(types.FETCH_PRODUCTS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_PRODUCT_SUCCESS, response)

    resolve(response)
  })
}

export const removeResourceFromRelationships = ({ commit }, productId) => {
  return new Promise((resolve, reject) => {
    commit(types.DELETE_PRODUCT_SUCCESS, productId)

    resolve(productId)
  })
}

export const removeResourcesFromRelationships = ({ commit }, productIds) => {
  return new Promise((resolve, reject) => {
    commit(types.DELETE_PRODUCTS_SUCCESS, productIds)

    resolve(productIds)
  })
}
