import * as types from '../mutation-types'
import User from '@models/user'

export const fetchOddPayInvoices = ({ dispatch, commit }, options = {}) => {
  commit(types.API_REQUEST_START, 'fetchOddPayInvoices')

  return new Promise((resolve, reject) => {
    User.fetchOddPayInvoices(options)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'fetchOddPayInvoices')
        dispatch(
          'oddPayInvoices/receiveResourcesFromRelationshipsWithReplace',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchOddPayInvoices,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchCurrentSubscriptionInvoice = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchCurrentSubscriptionInvoice')

  return new Promise((resolve, reject) => {
    User.fetchCurrentSubscriptionInvoice()
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'fetchCurrentSubscriptionInvoice')
        dispatch(
          'oddPayInvoices/getCurrentSubscriptionInvoiceFromRelationship',
          response,
          {
            root: true
          }
        )
        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch('productImages/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchCurrentSubscriptionInvoice,
            ref: {
              dispatch,
              commit
            },
            params: {}
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const assignVariantToOddPayInvoice = (
  { dispatch, commit },
  oddPayInvoice
) => {
  commit(types.API_REQUEST_START, 'assignVariantToOddPayInvoice')

  return new Promise((resolve, reject) => {
    User.assignVariantToOddPayInvoice(oddPayInvoice)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'assignVariantToOddPayInvoice')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: assignVariantToOddPayInvoice,
            ref: {
              dispatch,
              commit
            },
            params: oddPayInvoice
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const confirmOddPayInvoice = ({ dispatch, commit }, oddPayInvoice) => {
  commit(types.API_REQUEST_START, 'confirmOddPayInvoice')

  return new Promise((resolve, reject) => {
    User.confirmOddPayInvoice(oddPayInvoice)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'confirmOddPayInvoice')
        dispatch('oddPayInvoices/getResourceFromRelationship', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        oddPayInvoice.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: confirmOddPayInvoice,
            ref: {
              dispatch,
              commit
            },
            params: oddPayInvoice
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const makePaymentForOddPayInvoice = (
  { dispatch, commit },
  oddPayInvoice
) => {
  commit(types.API_REQUEST_START, 'makePaymentForOddPayInvoice')

  return new Promise((resolve, reject) => {
    User.makePaymentForOddPayInvoice(oddPayInvoice)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'makePaymentForOddPayInvoice')
        dispatch('oddPayInvoices/getResourceFromRelationship', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        oddPayInvoice.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: makePaymentForOddPayInvoice,
            ref: {
              dispatch,
              commit
            },
            params: oddPayInvoice
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
