export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const GET_RELATED_USERS_SUCCESS = 'GET_RELATED_USERS_SUCCESS'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const FETCH_USER_CHILDREN_SUCCESS = 'FETCH_USER_CHILDREN_SUCCESS'
export const ADD_USER_CHILD_SUCCESS = 'ADD_USER_CHILD_SUCCESS'
export const UPDATE_USER_CHILD_SUCCESS = 'UPDATE_USER_CHILD_SUCCESS'
export const DELETE_USER_CHILD_SUCCESS = 'DELETE_USER_CHILD_SUCCESS'
export const UPDATE_CURRENCY_REFERENCE_SUCCESS =
  'UPDATE_CURRENCY_REFERENCE_SUCCESS'

export const FETCH_USER_CREDIT_CARDS_SUCCESS = 'FETCH_USER_CREDIT_CARDS_SUCCESS'
export const ADD_USER_CREDIT_CARD_SUCCESS = 'ADD_USER_CREDIT_CARD_SUCCESS'
export const UPDATE_USER_CREDIT_CARD_SUCCESS = 'UPDATE_USER_CREDIT_CARD_SUCCESS'
export const DELETE_USER_CREDIT_CARD_SUCCESS = 'DELETE_USER_CREDIT_CARD_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
