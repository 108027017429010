export const FETCH_PROMOTER_SHARE_VARIANT_SHIPS_SUCCESS =
  'FETCH_PROMOTER_SHARE_VARIANT_SHIPS_SUCCESS'
export const GET_RELATED_PROMOTER_SHARE_VARIANT_SHIPS_SUCCESS =
  'GET_RELATED_PROMOTER_SHARE_VARIANT_SHIPS_SUCCESS'
export const GET_PROMOTER_SHARE_VARIANT_SHIP_SUCCESS =
  'GET_PROMOTER_SHARE_VARIANT_SHIP_SUCCESS'
export const ADD_PROMOTER_SHARE_VARIANT_SHIP_SUCCESS =
  'ADD_PROMOTER_SHARE_VARIANT_SHIP_SUCCESS'
export const UPDATE_PROMOTER_SHARE_VARIANT_SHIP_SUCCESS =
  'UPDATE_PROMOTER_SHARE_VARIANT_SHIP_SUCCESS'
export const DELETE_PROMOTER_SHARE_VARIANT_SHIP_SUCCESS =
  'DELETE_PROMOTER_SHARE_VARIANT_SHIP_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
