export const FETCH_PROMOTER_EVENTS_SUCCESS = 'FETCH_PROMOTER_EVENTS_SUCCESS'
export const GET_RELATED_PROMOTER_EVENTS_SUCCESS =
  'GET_RELATED_PROMOTER_EVENTS_SUCCESS'
export const GET_PROMOTER_EVENT_SUCCESS = 'GET_PROMOTER_EVENT_SUCCESS'
export const FETCH_DEFAULT_PROMOTER_EVENT_SUCCESS =
  'FETCH_DEFAULT_PROMOTER_EVENT_SUCCESS'
export const ADD_PROMOTER_EVENT_SUCCESS = 'ADD_PROMOTER_EVENT_SUCCESS'
export const UPDATE_PROMOTER_EVENT_SUCCESS = 'UPDATE_PROMOTER_EVENT_SUCCESS'
export const DELETE_PROMOTER_EVENT_SUCCESS = 'DELETE_PROMOTER_EVENT_SUCCESS'

export const FETCH_PROMOTER_EVENT_PROFIT_SHARE_RULES_SUCCESS =
  'FETCH_PROMOTER_EVENT_PROFIT_SHARE_RULES_SUCCESS'
export const FETCH_PROMOTER_EVENT_DEFAULT_PROFIT_CALCULATE_METHODS_SUCCESS =
  'FETCH_PROMOTER_EVENT_DEFAULT_PROFIT_CALCULATE_METHODS_SUCCESS'
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS'
export const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
