import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'
import ExtraQueryStringParseService from '../services/extra_query_string_parse_service.js'
import moment from 'moment'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'products',
  attributes: [
    'appreciation_period',
    'brand_id',
    'brand_name',
    'card_description',
    'categories',
    'category_ids',
    'consumer_price',
    'cost_price',
    'cover',
    'created_at',
    'depth',
    'description',
    'discount_rate',
    'discounted_price',
    'height',
    'id',
    'images',
    'indicators',
    'is_available',
    'is_preorder',
    'is_sold_out',
    'master',
    'meta_description',
    'meta_keywords',
    'meta_title',
    'name',
    'options',
    'original_price',
    'preserve_detail',
    'promotion_detail',
    'properties',
    'safe_stock_throttle',
    'sell_price',
    'shipping_category_id',
    'shipping_detail',
    'sku',
    'slug',
    'subtitle_1',
    'subtitle_2',
    'support_document_detail',
    'support_documents',
    'tag_list',
    'total_stock',
    'updated_at',
    'variants',
    'weight',
    'width',
    'bonus_deductible',
    'is_member_discountable',
    'is_tax_free',
    'is_age_restricted',
    'is_allow_backorder',
    'is_subscription',
    'subscription_info',
    'release_date'
  ],
  editableAttributes: [
    'appreciation_period',
    'brand_id',
    'card_description',
    'category_ids',
    'depth',
    'description',
    'height',
    'indicators',
    'is_available',
    'is_preorder',
    'meta_description',
    'meta_keywords',
    'meta_title',
    'name',
    'option_types',
    'preserve_detail',
    'price',
    'promotion_detail',
    'properties',
    'safe_stock_throttle',
    'shipping_category_id',
    'shipping_detail',
    'sku',
    'slug',
    'support_document_detail',
    'tag_list',
    'uploaded_attachment_ids',
    'weight',
    'width',
    'duplicate_variants',
    'is_member_discountable',
    'is_tax_free',
    'is_age_restricted',
    'is_subscription',
    'subscription_info',
    'release_date'
  ]
}

export default class Product extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static all(options = {}) {
    return axios.get(
      `${new this().apiBasePath()}?${FetchingDataOptionsService.call(
        options
      )}${ExtraQueryStringParseService.call(options)}`
    )
  }

  static onSaleProducts(options) {
    return axios.get(
      `${new this().apiBasePath()}/on_sale?${FetchingDataOptionsService.call(
        options
      )}${ExtraQueryStringParseService.call(options)}`
    )
  }

  static hotProducts(options) {
    return axios.get(
      `${new this().apiBasePath()}/hot?${FetchingDataOptionsService.call(
        options
      )}${ExtraQueryStringParseService.call(options)}`
    )
  }

  static uploadImages(formData) {
    return axios.post(`${new this().apiBasePath()}/images`, formData)
  }

  static uploadAttachments(formData) {
    return axios.post(
      `${new this().apiBasePath({
        withResourceType: false
      })}/editor_attachments`,
      formData
    )
  }

  fetchSupportDocuments() {
    return axios.get(`${this.apiBasePath()}/${this.id}/support_documents`)
  }

  fetchDistributionBases(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/distribution_bases?${FetchingDataOptionsService.call(options)}`
    )
  }

  uploadSupportDocument(formData) {
    return axios.post(
      `${this.apiBasePath()}/${this.id}/support_documents`,
      formData
    )
  }

  collect() {
    return axios.post(`${this.apiBasePath()}/${this.id}/collect`)
  }

  uncollect() {
    return axios.delete(`${this.apiBasePath()}/${this.id}/collect`)
  }

  duplicate() {
    return axios.post(
      `${this.apiBasePath()}/${this.id}/duplicate`,
      this.requestBody()
    )
  }

  checkVariantsAvailability() {
    return axios.get(
      `${this.apiBasePath()}/${this.id}/check_variants_availability`
    )
  }

  toggleAvailability() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_availability`)
  }

  toggleAvailabilityWithAllVariants() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/toggle_availability_with_all_variants`
    )
  }

  // helpers

  static get sortableFields() {
    return ['created_at', 'name', 'master.sell_price_cents']
  }

  hasDiscount() {
    return this.discount_rate < 1
  }

  requestBody() {
    const priceColumns = ['cost', 'original', 'sell', 'discounted']

    // Price columns must be [Number]
    priceColumns.forEach((element) => {
      this.price[element] = parseInt(this.price[element])
      if (this.duplicate_variants && this.duplicate_variants instanceof Array) {
        this.duplicate_variants.forEach((variant) => {
          variant.price[element] = parseInt(variant.price[element])
        })
      }
    })

    return {
      data: {
        type: OPTIONS.resourceType,
        attributes: this.attributes()
      }
    }
  }

  displayDiscountRate() {
    let rateNumber = this.discount_rate.toFixed(2).toString().split('.')[1]

    if (rateNumber.slice(-1) === '0') rateNumber = rateNumber.substr(0, 1)

    return `${rateNumber} ${I18n.t(
      'activerecord.attributes.product.discount_unit'
    )}`
  }

  noPriceData() {
    return this.sell_price === 0
  }

  displaySize() {
    const properties = ['width', 'depth', 'height']
    let result = []

    properties.forEach((property) => {
      if (this[property])
        result.push(
          `${I18n.t(`activerecord.attributes.product.${property}`)} ${
            this[property]
          }`
        )
    })

    return result.join(' × ')
  }

  hasVariants() {
    return this.variants.length > 0
  }

  coverImageThumb() {
    return this.cover.square.url
  }

  toggleBonusDeductible() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_bonus_deductible`)
  }

  toggleMemberLevelDiscountable() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/toggle_member_level_discountable`
    )
  }

  toggleIsTaxFree() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_is_tax_free`)
  }

  isPurchasable() {
    return this.is_allow_backorder || !this.is_sold_out
  }

  get isReleased() {
    const releaseDate = this.release_date

    if (!releaseDate) return true

    return moment(releaseDate, 'X').isBefore()
  }
}
