import PromoterShareVariantShip from '../../resource_models/promoter_share_variant_ship'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map(
    (id) => new PromoterShareVariantShip(state.entities[id])
  )
}

export const find = (state) => (id) => {
  return new PromoterShareVariantShip(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
