import axios from 'axios'
import moment from 'moment'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_events',
  attributes: [
    'id',
    'promoter_id',
    'created_at',
    'description',
    'ended_at',
    'is_enabled',
    'is_locked_profit_share_rule',
    'name',
    'started_at',
    'updated_at',
    'product_ships',
    'share_ranges',
    'shares',
    'cover',
    'summary',
    'token',
    'default_profit_ratio',
    'default_profit_calculate_method',
    'profit_share_rule',
    'is_default',
    'sign_up_token'
  ],
  editableAttributes: [
    'name',
    'promoter_id',
    'started_at',
    'ended_at',
    'is_enabled',
    'is_locked_profit_share_rule',
    'description',
    'uploaded_attachment_ids',
    'summary',
    'default_profit_ratio',
    'default_profit_calculate_method',
    'profit_share_rule',
    'source_promoter_event_id',
    'is_default'
  ]
}

export default class PromoterEvent extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  /**
   * 從 server 取得所有 promoter event 可用的 rules 列表
   *
   * @returns {promise}
   */
  static profitShareRules() {
    return axios.get(`${new this().apiBasePath()}/profit_share_rules`)
  }

  static defaultProfitCalculateMethods() {
    return axios.get(
      `${new this().apiBasePath()}/default_profit_calculate_methods`
    )
  }

  updateCover(formData) {
    return axios.put(`${this.apiBasePath()}/${this.id}/cover`, formData)
  }

  removeCover() {
    return axios.delete(`${this.apiBasePath()}/${this.id}/cover`)
  }

  hasCover() {
    return this.cover && this.cover.url !== null
  }

  toggleEnabled() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_enabled`)
  }

  toggleEnabledProfitShare() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/toggle_enabled_profit_share`
    )
  }

  toggleLockedProfitShareRule() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/toggle_locked_profit_share_rule`
    )
  }

  static fetchDashboardData(dataType, options) {
    let optionsQueryString = Object.keys(options)
      .map((key) => `${key}=${options[key]}`)
      .join('&')
    return axios.get(
      `${new this().apiBasePath()}/dashboard/${dataType}?${optionsQueryString}`
    )
  }

  static fetchStats(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    queryString += `&x_field=${options.x_field}`

    options.y_fields.forEach((yField) => {
      queryString += `&y_fields[]=${yField}`
    })

    return axios.get(`${new this().apiBasePath()}/stats?${queryString}`)
  }

  // default event
  static getDefaultEvent() {
    return axios.get(`${new this().apiBasePath()}/default_event`)
  }

  static revokeSignUpToken() {
    return axios.post(`${new this().apiBasePath()}/revoke_sign_up_token`)
  }

  // productShips handlers

  createProductShips(productIds) {
    const requestBody = {
      data: {
        type: 'promoter-event-product-ships',
        attributes: {
          product_ids: productIds
        }
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${this.id}/product_ships`,
      requestBody
    )
  }

  updateProductShips(productShips) {
    const requestBody = {
      data: {
        type: 'promoter-event-product-ships',
        attributes: {
          product_ships: productShips
        }
      }
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/product_ships`,
      requestBody
    )
  }

  deleteProductShips(productship) {
    return axios.delete(
      `${this.apiBasePath()}/${this.id}/product_ships/${productship.id}`
    )
  }

  fetchProducts(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/products?${FetchingDataOptionsService.call(options)}`
    )
  }

  findProduct(productId) {
    return axios.get(`${this.apiBasePath()}/${this.id}/products/${productId}`)
  }

  // shareRanges handlers

  createShareRange({ lowerPrice, profitRatio }) {
    const requestBody = {
      data: {
        type: 'promoter-event-share-ranges',
        attributes: {
          lower_price: lowerPrice,
          profit_ratio: profitRatio
        }
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${this.id}/share_ranges`,
      requestBody
    )
  }

  updateShareRanges(shareRanges) {
    const requestBody = {
      data: {
        type: 'promoter-event-share-ranges',
        attributes: {
          share_ranges: shareRanges
        }
      }
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/share_ranges`,
      requestBody
    )
  }

  deleteShareRange(shareRange) {
    return axios.delete(
      `${this.apiBasePath()}/${this.id}/share_ranges/${shareRange.id}`
    )
  }

  // order_ships
  fetchOrders(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/orders?${FetchingDataOptionsService.call(options)}`
    )
  }

  static fetchOrderStats(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    queryString += `&x_field=${options.x_field}`

    options.y_fields.forEach((yField) => {
      queryString += `&y_fields[]=${yField}`
    })

    return axios.get(`${new this().apiBasePath()}/orders/stats?${queryString}`)
  }

  // duplicate
  duplicate() {
    return axios.post(`${this.apiBasePath()}/duplicate`, this.requestBody())
  }

  // shares
  fetchShares(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/shares?${FetchingDataOptionsService.call(options)}`
    )
  }
}
