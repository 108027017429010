import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_share_variant_ships',
  attributes: [
    'id',
    'share_product_ship_id',
    'event_variant_ship_id',
    'profit_ratio',
    'profit_calculate_method',
    'profit_price',
    'is_sold_out',
    'created_at',
    'updated_at'
  ],
  editableAttributes: []
}

export default class PromoterShareVariantShip extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
