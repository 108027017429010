import PromoterShare from '../../resource_models/promoter_share'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new PromoterShare(state.entities[id]))
}

export const find = (state) => (id) => {
  return new PromoterShare(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}

export const dashboardData =
  (state) =>
  (scope = null) => {
    if (scope) {
      return state.dashboard[scope]
    } else {
      return state.dashboard
    }
  }

export const descendants = (state) => (thisPromoterShare) => {
  return state.result
    .map((id) => new PromoterShare(state.entities[id]))
    .filter((promoterShare) => {
      return (
        promoterShare.lft > thisPromoterShare.lft &&
        promoterShare.rgt < thisPromoterShare.rgt
      )
    })
}

export const template = (state) => {
  return state.result
    .map((id) => new PromoterShare(state.entities[id]))
    .find((promoterShare) => {
      return promoterShare.is_template
    })
}
